// import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { selectAuth } from "../store/authApiSlice";

export default function ProtectedRoute() {
  const { username } = useSelector(selectAuth);
  const location = useLocation()
  return (
    username
      ? <Outlet />
      : <Navigate to="/login" state={{ from: location }} replace />
  )
}
