import { graphqlApiMutation, graphqlApiQuery } from "./baseGraphqlApi";
import { cancelOrder, confirmOpenDoor, createSwapOrder, validateQrCode } from "../../graphql/mutations";
import { getMySwapOrderBySpName } from "../../graphql/queries";

const sharingpointSwapOrderApiMutation = graphqlApiMutation.injectEndpoints({
  endpoints: builder => ({
    confirmOpenDoor: builder.mutation({
      query: (token) => ({
        queryName: confirmOpenDoor,
        data: { token },
      }),
      transformResponse: (response) => response,
    }),
    cancelSwapOrder: builder.mutation({
      query: (token) => ({
        queryName: cancelOrder,
        data: { token },
      }),
      transformResponse: (response) => response,
    }),

    validateQrCode: builder.mutation({
      query: (unique_validation_hash_key) => ({
        queryName: validateQrCode,
        data: { code: unique_validation_hash_key },
      }),
      transformResponse: (response) => response,
    }),

    createSwapOrder: builder.mutation({
      query: ({ battery_count, battery_type, unique_validation_hash_key, sharing_point_name }) => ({
        queryName: createSwapOrder,
        data: {
          battery_count, battery_type,
          unique_validation_hash_key, sharing_point_name
        },
      }),
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
})
const sharingpointSwapOrderApiQuery = graphqlApiQuery.injectEndpoints({
  endpoints: builder => ({
    fetchSwapOrderBySpName: builder.query({
      query: (token) => ({
        queryName: getMySwapOrderBySpName,
        data: { token },
      }),
      transformResponse: (response) => {
        return response
      },
    }),
  }),
  overrideExisting: false,
})
// console.log(sharingpointApi)

export const {
  useValidateQrCodeMutation, useCreateSwapOrderMutation,
  useConfirmOpenDoorMutation,
  useCancelSwapOrderMutation,
} = sharingpointSwapOrderApiMutation

export const {
  useFetchSwapOrderBySpNameQuery,
} = sharingpointSwapOrderApiQuery