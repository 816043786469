import i18n from "i18next";
// import detector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

import translationEN from './assets/locales/en/translation.json';
import translationDE from './assets/locales/de/translation.json';
import translationFR from './assets/locales/fr/translation.json';
import translationES from './assets/locales/es/translation.json';


// the translations
const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  },
  es: {
    translation: translationES
  },
  fr: {
    translation: translationFR
  }
};

i18n
  // .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;