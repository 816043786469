import React, { useMemo } from 'react'
import SwappingScreenLayout from '../../shared/components/SwappingScreenLayout'
import RatingSection from '../ServiceRatting/RatingSection'
import { Grid, GridColumn, Icon } from 'semantic-ui-react'
import { upperFirst } from 'lodash'
import { useUpdateRatingMutation } from '../../store/services/servicesRatingApi'
import { useDispatch } from 'react-redux'
import { logOut } from '../../store/authApiSlice'
import { selectAuth } from "../../store/authApiSlice";
import { useSelector } from 'react-redux';


function SwapCompleted({ t, swapOrderBySpName, ratingOptions }) {
  // const { username } = useSelector(selectAuth)
  const dispatch = useDispatch()

  const {
    battery_count,
    battery_type,
    swap_state,
    swap_status_map,
    box_status,
    confirmation_token,
    problems,
  } = swapOrderBySpName;

  const doors = useMemo(() => {
    if (!swap_status_map) return;
    return Object.keys(JSON.parse(swap_status_map)).join(" & ");
  }, [swap_status_map]);
  const [updateRating] = useUpdateRatingMutation()


  const handelOnSendingRating = ({ rating, reason, validation_hash_key }) => {
    // const swap_validation_hash_key = swapOrderBySpName.validation_hash_key
    updateRating({ rating, reason, validation_hash_key })
    dispatch(logOut());
  }

  const handelOnIgnoreRating = () => {
    dispatch(logOut());
  }

  // console.log(ratingOptions)
  return (
    <SwappingScreenLayout
      Right={
        <div style={{
          height: "450px",
          width: "100%",
        }}>{ratingOptions &&
          <RatingSection {...{ t, handelOnSendingRating, handelOnIgnoreRating }} options={ratingOptions.data} />
          }
        </div>
      }
      Left={
        <Grid style={{ width: 450, margin: "auto", marginTop: 0 }}>
          <GridColumn width="16">
            <Icon name="check circle" color="green" size="massive" />
          </GridColumn>
          <GridColumn width="7" style={{ paddingTop: 6, margin: 0 }}>
            <span
              style={{
                fontSize: 24,
                fontColor: "#444",
                color: "#444",
                fontWeight: 1,
                float: "left",
              }}
            >
              Battery type
            </span>
          </GridColumn>
          <GridColumn
            width="9"
            textAlign="right"
            style={{ paddingTop: 6, margin: 0 }}
          >
            <span
              as="h2"
              style={{
                fontColor: "#444",
                color: "#444",
                fontSize: 24,
                fontWeight: 1,
                float: "right",
              }}
            >
              {upperFirst(battery_type)}
            </span>
          </GridColumn>
          {battery_count > 0 && (
            <>
              <GridColumn
                width="10"
                style={{ paddingTop: 6, margin: 0 }}
              >
                <span
                  style={{
                    fontSize: 24,
                    fontColor: "#444",
                    color: "#444",
                    fontWeight: 1,
                    float: "left",
                  }}
                >
                  Requested
                </span>
              </GridColumn>
              <GridColumn
                width="6"
                textAlign="right"
                style={{ paddingTop: 6, margin: 0 }}
              >
                <span
                  as="h2"
                  style={{
                    fontColor: "#444",
                    color: "#444",
                    fontSize: 24,
                    fontWeight: 1,
                    float: "right",
                  }}
                >
                  {battery_count}
                </span>
              </GridColumn>
            </>
          )}

          <GridColumn
            width="10"
            textAlign="left"
            style={{ paddingTop: 6, margin: 0 }}
          >
            <span
              style={{
                fontSize: 24,
                fontColor: "#444",
                color: "#444",
                fontWeight: 1,
                float: "left",
              }}
            >
              In/Out
            </span>
          </GridColumn>
          <GridColumn
            width="6"
            textAlign="right"
            style={{ paddingTop: 6, margin: 0 }}
          >
            <span
              as="h2"
              style={{
                fontColor: "#444",
                color: "#444",
                fontSize: 24,
                fontWeight: 1,
                float: "right",
              }}
            >
              {box_status.global_box_in}/
              {box_status.global_box_out}
            </span>
          </GridColumn>

          <GridColumn
            width="10"
            textAlign="left"
            style={{ paddingTop: 6, margin: 0 }}
          >
            <span
              style={{
                fontSize: 24,
                fontColor: "#444",
                color: "#444",
                fontWeight: 1,
                float: "left",
              }}
            >
              Doors
            </span>
          </GridColumn>
          <GridColumn
            width="6"
            textAlign="right"
            style={{ paddingTop: 6, margin: 0 }}
          >
            <span
              as="h2"
              style={{
                fontColor: "#444",
                color: "#444",
                fontSize: 24,
                fontWeight: 1,
                float: "right",
              }}
            >
              {doors}
            </span>
          </GridColumn>

          <GridColumn
            width="10"
            textAlign="left"
            style={{ paddingTop: 6, margin: 0 }}
          >
            <span
              style={{
                fontSize: 24,
                fontColor: "#444",
                color: "#444",
                fontWeight: 1,
                float: "left",
              }}
            >
              Has problems
            </span>
          </GridColumn>
          <GridColumn
            width="6"
            textAlign="right"
            style={{ paddingTop: 6, margin: 0 }}
          >
            <span
              as="h2"
              style={{
                fontColor: "#444",
                color: "#444",
                fontSize: 24,
                fontWeight: 1,
                float: "right",
              }}
            >
              {swap_state.includes("PROBLEMS") ? "Yes" : "No"}
            </span>
          </GridColumn>
          <span
            style={{
              fontColor: "#444",
              color: "#444",
              fontSize: 40,
              fontWeight: 2,
              margin: "20px auto"

            }}
          >
            {t("COMPLETED")}
          </span>

        </Grid>
      }
      sameLayout={true}
    />
  )
}

export default SwapCompleted