import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import awsconfig from "../../aws-exports/aws-exports-rel";

// Amplify.configure(awsconfig);
const baseQuery = fetchBaseQuery({
  baseUrl: awsconfig.FLASK_REST_API,
});

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  endpoints: (builder) => ({
    rfidLogin: builder.mutation({
      query: ({ rfidTag }) => ({
        url: '/rfid/validate',
        method: 'POST',
        body: {
          sp_serial_number: localStorage.getItem("my_sp_serial_number_is"),
          rfid_number: rfidTag,
          client_id: awsconfig.aws_user_pools_web_client_id,
        }
      }),
    }),
  })
})

export const { useRfidLoginMutation } = authApi