import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { API, graphqlOperation } from "aws-amplify";

const customGraphqlBaseQuery = () => async ({ queryName, data }) => {
  try {
    // console.log(data)
    const responseData = await callGraphqlApi({ queryName, data })
    return { data: responseData }
    // return { data: null }
  } catch (error) {
    // console.log(error)
    return { error }
  }
}

export const graphqlApiQuery = createApi({
  reducerPath: "graphqlApiQuery",
  baseQuery: customGraphqlBaseQuery(),
  // tagTypes: [
  //   'Client',
  // ],
  endpoints: () => ({}),
})

export const graphqlApiMutation = createApi({
  reducerPath: "graphqlApiMutation",
  baseQuery: customGraphqlBaseQuery(),
  // tagTypes: [
  //   'Client',
  // ],
  endpoints: () => ({}),
})

async function callGraphqlApi({ queryName, data }) {
  try {
    // console.log({ ...data })
    const response = await API.graphql(graphqlOperation(queryName, { ...data }))
    const responseData = response.data
    return responseData
  } catch (error) {
    console.error('Error while calling', error)
    console.log('QueryName error: ', queryName)
    console.log('Data error: ', data)
    throw (error)
  }
}