import { createSlice, } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// import NotificationContent from "../components/NotificationContent";
import "react-toastify/dist/ReactToastify.css";

const notificationErrorSlice = createSlice({
  name: "notificationError",
  initialState: {
    // message: "",
    // statusCode: ""
  },
  reducers: {
    showNotificationError: (state, actions) => {
      // let iconName = "close"
      // if (actions.payload.iconName) {
      //   iconName = actions.payload.iconName
      // }
      toast.warn(`${actions.payload.message}`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        className: 'toast-message'
      });
      // toast.error(
      //   <NotificationContent iconName={iconName} message={actions.payload.message} />,
      //   {
      //     position: "bottom-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: false,
      //     progress: false,
      //   }
      // );
    }
  }
});

export const { showNotificationError } = notificationErrorSlice.actions;

export default notificationErrorSlice.reducer;
