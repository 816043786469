import { createSlice } from "@reduxjs/toolkit";


const targetSharingpointSlice = createSlice({
  name: "targetSharingpoint",
  initialState: {
    data: [],
    // error: "",
    selectedSharingpoint: null,
    // isLoading: false,
    // success: false,
    // selectSharingpointSuccess: null
  },
  reducers: {
    setTargetSharingpoint: (state, action) => {
      const { selectedSharingpoint, data } = action.payload
      state.selectedSharingpoint = selectedSharingpoint;
      state.data = data
      // state.success = success
    },
    removeTargetSharingpoint: state => {
      state.selectedSharingpoint = null;
      state.data = []
      // state.success = false
    }
  },

});

export const { setTargetSharingpoint, removeTargetSharingpoint } = targetSharingpointSlice.actions;
export default targetSharingpointSlice.reducer;
export const selectTargetSharingpoint = (state) => state.targetSharingpoint
