import { Button, Grid, GridColumn, Header, Icon, Statistic } from "semantic-ui-react";
import SwappingScreenLayout from "../../shared/components/SwappingScreenLayout";

import React from 'react'
import { useCancelSwapOrderMutation, useConfirmOpenDoorMutation } from "../../store/services/sharingpointSwapOrderApi";

function SwapHasProblem({ swapOrderBySpName }) {
  const { battery_count, box_status, confirmation_token } = swapOrderBySpName
  const [confirmOpenDoor, { isLoading: isLoadingConfirmOpenDoor, success: confirmOpenDoorSuccessful }] = useConfirmOpenDoorMutation();
  const [cancelSwapOrder, { isLoading: isLoadingForceCompletion }] = useCancelSwapOrderMutation()


  return (
    <SwappingScreenLayout
      Left={
        <Icon
          name="warning sign"
          style={{ color: "#444" }}
          size="massive"
        />
      }
      Right={
        <Grid verticalAlign="middle" textAlign="center" style={{ pointerEvents: isLoadingConfirmOpenDoor || isLoadingForceCompletion ? "none" : "" }}>
          <GridColumn width="16" textAlign="center">
            <Statistic style={{ margin: "0px 20px 0px 20px" }}>
              <Statistic.Label style={{ color: "#444" }}>
                Requested
              </Statistic.Label>
              <Statistic.Value style={{ color: "#444" }}>
                {battery_count}
              </Statistic.Value>
            </Statistic>

            <Statistic>
              <Statistic.Label style={{ color: "#444" }}>
                In/Out
              </Statistic.Label>
              <Statistic.Value style={{ color: "#444" }}>
                {box_status.global_box_in}/{box_status.global_box_out}
              </Statistic.Value>
            </Statistic>
          </GridColumn>

          <GridColumn width="16">
            <Header
              as="h3"
              style={{ fontColor: "#444", color: "#444" }}
            >
              We recommend swapping one battery for another for an
              efficient use of the station.
            </Header>
          </GridColumn>

          <GridColumn width="16" textAlign="center">
            {/* <List>
        {messages.map((item) => (
          <ListItem style={{ color: "#444" }} key={item}>
            <Header
              as="h3"
              style={{ fontColor: "#444", color: "#444" }}
            >
              {
                <ListItem>
                  <Header
                    as="h3"
                    style={{ fontColor: "#444", color: "#444" }}
                  >
                    {item}
                  </Header>
                </ListItem>
              }
            </Header>
          </ListItem>
        ))}
      </List> */}

            <Header
              as="h3"
              style={{ fontColor: "#444", color: "#444" }}
            >
              Do you want to reopen doors and fix it?
            </Header>
          </GridColumn>

          <GridColumn width="8" textAlign="center">
            <Button
              loading={isLoadingConfirmOpenDoor && !confirmOpenDoorSuccessful}
              circular
              fluid
              style={{
                backgroundColor: "#ff5a00",
                color: "white",
              }}
              size="massive"
              width="8"
              content="Yes"
              onClick={() => {
                confirmOpenDoor(confirmation_token);
              }}
            />
          </GridColumn>
          <GridColumn width="8" textAlign="center">
            <Button
              loading={isLoadingForceCompletion}
              circular
              fluid
              style={{
                backgroundColor: "#444",
                color: "white",
              }}
              size="massive"
              width="8"
              content="No"
              onClick={() => cancelSwapOrder(confirmation_token)}
            />
          </GridColumn>
        </Grid>
      }
    />
  )
}

export default SwapHasProblem
