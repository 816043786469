import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAuth } from "../../store/authApiSlice";


export default function useRedirectScreen({ stationMinimalData }) {
  const navigate = useNavigate();
  const { allowedToFillIn, isAdmin, username } = useSelector(selectAuth);
  useEffect(() => {
    // console.log(`Detect user signed in: ${username}`);
    if (username) {
      if (isAdmin) {
        // navigate("/home-logged-in");
        // console.log(`navigate to /home-logged-in`);
        if (stationMinimalData) {
          navigate("/home-logged-in");
          console.log(`navigate to /home-logged-in`);
        } else {
          navigate("/settings");
          console.log(`navigate to /settings`);
        }
      } else {
        if (allowedToFillIn) {
          navigate("/open-doors");
          console.log(`navigate to /open-doors`);
        } else {
          navigate("/single-swap");
          console.log(`navigate to /single-swap`);
        }
      }
    }
  }, [username, isAdmin, allowedToFillIn]);
}
