import React from 'react'
import SwappingScreenLayout from '../../shared/components/SwappingScreenLayout';
import { Button, Icon } from 'semantic-ui-react';
import { logOut } from '../../store/authApiSlice';
import { useDispatch } from 'react-redux';

function SwapAborted() {
  const dispatch = useDispatch()
  return (
    <SwappingScreenLayout
      Left={
        <Icon
          name="warning circle"
          style={{ color: "#444" }}
          size="massive"
        />
      }
      Right={
        <>
          <p
            style={{
              fontColor: "#444",
              color: "#444",
              margin: "auto",
              fontSize: 35,
              marginTop: 190,
            }}
          >
            Swap aborted for inactivity!
          </p>

          <Button
            // loading={isLoadingLogout}
            circular
            style={{
              backgroundColor: "#ff5a00",
              color: "white",
              position: "absolute",
              bottom: 30,
              left: 200,
            }}
            size="massive"
            content="Finish"
            onClick={() => {
              dispatch(logOut());
            }}
          />
        </>
      }
    />
  )
}

export default SwapAborted