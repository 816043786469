import React, { useMemo, Fragment, useState } from "react";
import {
  Grid,
  GridColumn,
} from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import LeftBottomButton from "../LeftBottomButton";
import HeaderLayout from "../HeaderLayout";

import BatteryItem from "../../shared/components/BatteryItem"

import useTimeout from "../../shared/hooks/useTimeout"
import LogoutAlert from "../LogoutAlert"
import { selectAuth } from "../../store/authApiSlice";
import { selectTargetSharingpoint } from "../../store/targetSharingpointSlice";
import { useOpenMultipleDoorsMutation } from "../../store/services/openMultipleDoorsApi";
import { useValidateQrCodeMutation } from "../../store/services/sharingpointSwapOrderApi";

export default function OpenDoors({ t, stationMinimalData }) {
  const navigate = useNavigate();
  const routeParams = useParams();
  const [showLogoutAlert, setShowLogoutAlert] = useTimeout()
  const searchParams = routeParams;

  const { selectedSharingpoint, data: targetSharingpointData } = useSelector(selectTargetSharingpoint);
  const [validateQrCode] = useValidateQrCodeMutation()
  const [openMultipleDoors] = useOpenMultipleDoorsMutation()

  const { isAdmin } = useSelector(selectAuth);

  const { supported_batteries } = targetSharingpointData


  let selectedBatteryType = useMemo(() => {
    if (!searchParams) return;
    return searchParams.selectedBatteryType;
  }, [searchParams]);

  const [loading, setLoading] = useState(false);

  const onOpenDoor = async (battery_type) => {
    setLoading(true)
    navigate(`/open-doors?selectedBatteryType=${battery_type}`);
    if (!selectedSharingpoint.ongoing_swap) {
      const { data: validateQrCodeData } = await validateQrCode(targetSharingpointData.validation_hash_key)
      const unique_validation_hash_key = validateQrCodeData.validate_qr_code.data.unique_validation_hash_key

      const { data } = await openMultipleDoors({ token: unique_validation_hash_key, sharing_point_name: selectedSharingpoint, battery_type })
      // console.log(data)
      const { code, message } = data.openDoors;
      if (code === "200") {
        navigate("/swap/" + unique_validation_hash_key);
      } else {
        console.log(message)
      }
      setLoading(false)
    }
  }

  return (
    <>
      <Grid
        textAlign="center"
        columns="equal"
        style={{
          margin: "auto",
          width: "90%",
          position: "fixed",
          top: 200,
          zIndex: 50,
          left: 70
        }}
      >
        <HeaderLayout title={t("Open modules")} station={stationMinimalData} />
        {isAdmin ? <LeftBottomButton navigateTo={"/home-logged-in"} /> : <LeftBottomButton activateLogout={true} />}

        {!selectedSharingpoint && (
          <GridColumn textAlign="center" style={{ marginTop: 300, zIndex: 50 }}>
            <BeatLoader
              color={"#ff5a00"}
              loading={true}
              height={10}
              width={30}
            />
          </GridColumn>
        )}
        {showLogoutAlert ? <LogoutAlert setShowLogoutAlert={setShowLogoutAlert} /> : null}

        {!showLogoutAlert && selectedSharingpoint
          ? (
            <>
              {supported_batteries.map(item => (
                <Fragment key={item} >
                  {
                    <GridColumn
                      key={item}
                      verticalAlign="bottom"
                      textAlign="center"
                      style={{ marginTop: 50, maxWidth: "500px", pointerEvents: loading ? "none" : "" }}
                    >
                      <BatteryItem
                        {...{
                          selectedBatteryType,
                          isAdmin,
                          t
                        }}
                        key={item}
                        batteryType={item}
                        sharing_point_name={
                          selectedSharingpoint.sharing_point_name
                        }
                        allowedToForceSwap={
                          selectedSharingpoint.force_to_swap_allowed
                        }
                        handleClickOnBattery={onOpenDoor}
                      />
                    </GridColumn>
                  }
                </Fragment>
              ))}
            </>
          ) : null
        }
      </Grid>
    </>
  );
};