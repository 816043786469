import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import sound from "../../assets/sounds/yilf33i0eehk.mp3";
import { logOut } from "../../store/authApiSlice";
import { useNavigate } from "react-router-dom";

export default function useTimeout() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const start = () => {
    // let alertSound = new Audio(sound);
    // alertSound.play();
    // alertSound.play().catch(function (error) {
    //   console.log("Browser cannot play sound without user interaction first");
    // });
    setShowLogoutAlert(true);
  };
  useEffect(() => {
    let interval = setInterval(() => {
      if (localStorage.getItem("last_touch_event_at") === null) {
        // console.log("Not found localStorage of last_touch_event_at, set it to now")
        localStorage.setItem("last_touch_event_at", new Date().getTime());
      }
      if (
        localStorage.getItem("last_touch_event_at") &&
        new Date().getTime() - localStorage.getItem("last_touch_event_at") >=
        Number(process.env.REACT_APP_NOTIFY_TIMEOUT_MINUTE) * 60 * 1000
      ) {
        console.log("Start counting to logout user automatically");
        start();
      }
      if (
        localStorage.getItem("last_touch_event_at") &&
        new Date().getTime() - localStorage.getItem("last_touch_event_at") >=
        Number(process.env.REACT_APP_NOTIFY_TIMEOUT_MINUTE) * 60 * 1000 +
        Number(
          process.env.REACT_APP_DELAY_BEFORE_FORCING_USER_LOGOUT_SECOND
        ) *
        1000
      ) {
        console.log(
          "logout from select battery",
          new Date().getTime() - localStorage.getItem("last_touch_event_at")
        );
        dispatch(logOut());
        navigate('/')
      }
    }, 1500);
    return () => {
      // console.log("clear interval");
      clearInterval(interval);
    };
  }, []);
  return [showLogoutAlert, setShowLogoutAlert];
}
