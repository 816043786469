import { useEffect } from "react";
import { useFetchStationMinimalDataQuery } from "../store/services/stationMinimalDataApi";
import useInternetOnlineStatus from "../shared/hooks/useInternetOnlineStatus";

export default async function useFetchStationMinimalData({ setStationMinimalData }) {
  const { internetAvailable } = useInternetOnlineStatus();
  const sharing_point_name = localStorage.getItem("my_sp_serial_number_is");
  const { data, isSuccess } = useFetchStationMinimalDataQuery(sharing_point_name, {
    pollingInterval: 10000, skip: !sharing_point_name || !internetAvailable
  })
  useEffect(() => {
    if (isSuccess) {
      // console.log(data)
      setStationMinimalData(data?.data)
    } else {
      setStationMinimalData()
    }
  }, [data, isSuccess]);
}