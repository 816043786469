import React, { useMemo, useState } from 'react'
import { Grid, GridColumn, Header, Icon, Label, Placeholder } from 'semantic-ui-react'
import { upperFirst } from 'lodash';
import { ChargeModule, StateOfCharge } from "./SubComponents";
import SwappingScreenLayout from '../../shared/components/SwappingScreenLayout'
import useSetSelectedModuleNumberInitial from './useSetSelectedModuleNumberInitial';
import useUpdateLastInteraction from './useUpdateLastInteraction';


const iconMapping = {
  Present: require("../../assets/images/slots/slot_is_present.png"),
  Ready: require("../../assets/images/slots/slot_is_ready.png"),
  Empty: require("../../assets/images/slots/minus.png"),
  Error: require("../../assets/images/slots/battery-state-error.png"),
};

function SwapIsOpen({ t, swapOrderBySpName, stationMinimalData }) {
  const {
    battery_type,
    swap_status_map,
    box_status,
    problems,
  } = swapOrderBySpName;
  const [selectedModuleNumber, setSelectedModuleNumber] = useState(-1);
  const { boxes } = box_status || {}

  useSetSelectedModuleNumberInitial({ selectedModuleNumber, setSelectedModuleNumber, swap_status_map })

  useUpdateLastInteraction({ swap_status_map, boxes })

  const hasUnsolvedProblems = useMemo(() => {
    if (!swapOrderBySpName || !problems) return;
    Object.values(JSON.parse(problems)).forEach(({ solved }) => {
      if (solved) return solved;
    });
  }, [problems]);

  return (
    <SwappingScreenLayout
      Left={
        <Grid>
          {stationMinimalData &&
            stationMinimalData.modules.map((box, index) => (
              <ChargeModule
                key={index}
                {...{
                  module_number: box.box_number,
                  doorStatusMap: JSON.parse(swap_status_map),
                  setSelectedModuleNumber,
                  selectedModuleNumber,
                  module_count: stationMinimalData.modules.length,
                }}
              />
            ))
          }
        </Grid>
      }
      Right={
        <Grid verticalAlign="middle" textAlign="center">
          <GridColumn width="16" textAlign="left">
            <Header
              as="h1"
              style={{
                color: "#444",
                paddingBottom: 0,
                marginBottom: 0,
              }}
            >
              {selectedModuleNumber && +selectedModuleNumber !== -1 ? (
                `Module ${selectedModuleNumber}`
              ) : (
                <Placeholder>
                  <Placeholder.Line length={"short"} />
                </Placeholder>
              )}
            </Header>

            {box_status && (
              <>
                <Header
                  as="h2"
                  style={{ color: "#444", paddingTop: 0, marginTop: 0 }}
                >
                  {upperFirst(battery_type)} In:{" "}
                  {box_status.global_box_in} / Out:{" "}
                  {box_status.global_box_out}
                </Header>
              </>
            )}
          </GridColumn>
          <GridColumn width="8">
            {hasUnsolvedProblems && (
              <Label
                circular
                color="grey"
                size="massive"
                style={{ float: "right" }}
                content="Problem"
              />
            )}
          </GridColumn>
          <GridColumn width="16">
            <div
              size="medium"
              style={{ color: "#444", fontSize: 22, fontWeight: 300 }}
            >
              {t("swap_first_message")}
            </div>
          </GridColumn>
          <GridColumn width="16" textAlign="center">
            {selectedModuleNumber &&
              box_status.boxes &&
              box_status.boxes.length > 0 && (
                <Grid
                  centered
                  style={{
                    width: 500,
                  }}
                  className="module"
                >
                  {box_status &&
                    box_status.boxes &&
                    box_status.boxes.length > 0 &&
                    box_status.boxes.find(
                      (box) => box.box_id === +selectedModuleNumber
                    ) && (
                      <>
                        {["greenpack", "torrot", "kumpan"].includes(battery_type)
                          ?
                          <GridColumn width="16">
                            <div className="slot">
                              {box_status.boxes.find(
                                (box) =>
                                  box.box_id === +selectedModuleNumber
                              ).slots[0].state !== "Empty" ? (
                                <>
                                  <img
                                    src={
                                      iconMapping[
                                      box_status.boxes.find(
                                        (box) =>
                                          box.box_id ===
                                          +selectedModuleNumber
                                      ).slots[0].state
                                      ]
                                    }
                                    alt=""
                                    className="one-slot-sp-icon"
                                  />

                                  <StateOfCharge
                                    index={0}
                                    {...{
                                      selectedModuleNumber,
                                      box_status,
                                    }}
                                  />
                                </>
                              ) : (
                                <Icon
                                  name="minus"
                                  size="huge"
                                  style={{ color: "#444", margin: 5 }}
                                />
                              )}
                            </div>
                          </GridColumn>
                          : null
                        }

                        {["Okai B", "Segway"].includes(battery_type)
                          ?
                          <>
                            {console.log(`battery_type ${battery_type}`)}
                            <GridColumn width="8">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[0].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[0].state
                                        ]
                                      }
                                      alt=""
                                      className="foure-slot-sp-icon"
                                    />
                                    <StateOfCharge
                                      index={0}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                            <GridColumn width="8">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[2].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[2].state
                                        ]
                                      }
                                      alt=""
                                      className="foure-slot-sp-icon"
                                    />
                                    <StateOfCharge
                                      index={2}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                            <GridColumn width="8">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[1].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[1].state
                                        ]
                                      }
                                      alt=""
                                      className="foure-slot-sp-icon"
                                    />

                                    <StateOfCharge
                                      index={1}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                            <GridColumn width="8">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[3].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[3].state
                                        ]
                                      }
                                      alt=""
                                      className="foure-slot-sp-icon"
                                    />
                                    <StateOfCharge
                                      index={3}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                          </>
                          : null
                        }

                        {["AES eBike Akku 2.0 48V"].includes(battery_type)
                          ?
                          <>
                            <GridColumn
                              width="4"
                              verticalAlign="middle"
                            >
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[0].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[0].state
                                        ]
                                      }
                                      alt=""
                                      className="slot-icon"
                                    />
                                    <StateOfCharge
                                      index={0}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                            <GridColumn width="4">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[1].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[1].state
                                        ]
                                      }
                                      alt=""
                                      className="slot-icon"
                                    />
                                    <StateOfCharge
                                      index={1}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                            <GridColumn width="4">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[2].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[2].state
                                        ]
                                      }
                                      alt=""
                                      className="slot-icon"
                                    />
                                    <StateOfCharge
                                      index={2}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                            <GridColumn width="4">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[3].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[3].state
                                        ]
                                      }
                                      alt=""
                                      className="slot-icon"
                                    />
                                    <StateOfCharge
                                      index={3}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                          </>
                          : null
                        }

                        {["Okai A"].includes(battery_type)
                          ?
                          <>
                            {console.log(`battery_type ${battery_type}`)}
                            <GridColumn
                              width="5"
                              verticalAlign="middle"
                            >
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[0].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[0].state
                                        ]
                                      }
                                      alt=""
                                      className="slot-icon"
                                    />
                                    <StateOfCharge
                                      index={0}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                            <GridColumn width="5">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[2].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[2].state
                                        ]
                                      }
                                      alt=""
                                      className="slot-icon"
                                    />
                                    <StateOfCharge
                                      index={2}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                            <GridColumn width="5">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[4].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[4].state
                                        ]
                                      }
                                      alt=""
                                      className="slot-icon"
                                    />
                                    <StateOfCharge
                                      index={4}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                            <GridColumn width="5">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[1].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[1].state
                                        ]
                                      }
                                      alt=""
                                      className="slot-icon"
                                    />
                                    <StateOfCharge
                                      index={1}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>

                            <GridColumn width="5">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[3].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[3].state
                                        ]
                                      }
                                      alt=""
                                      className="slot-icon"
                                    />
                                    <StateOfCharge
                                      index={3}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                            <GridColumn width="5">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[5].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[5].state
                                        ]
                                      }
                                      alt=""
                                      className="slot-icon"
                                    />
                                    <StateOfCharge
                                      index={5}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                          </>
                          : null
                        }
                        {battery_type.toLowerCase().includes("niu")
                          ?
                          <>
                            <GridColumn
                              width="8"
                              verticalAlign="middle"
                            >
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[0].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[0].state
                                        ]
                                      }
                                      alt=""
                                      className="slot-icon"
                                      style={{ width: "60%" }}
                                    />
                                    <StateOfCharge
                                      index={0}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                            <GridColumn width="8">
                              <div className="slot">
                                {box_status.boxes.find(
                                  (box) =>
                                    box.box_id === +selectedModuleNumber
                                ).slots[1].state !== "Empty" ? (
                                  <>
                                    <img
                                      src={
                                        iconMapping[
                                        box_status.boxes.find(
                                          (box) =>
                                            box.box_id ===
                                            +selectedModuleNumber
                                        ).slots[1].state
                                        ]
                                      }
                                      alt=""
                                      className="slot-icon"
                                      style={{ width: "60%" }}
                                    />
                                    <StateOfCharge
                                      index={2}
                                      {...{
                                        selectedModuleNumber,
                                        box_status,
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Icon
                                    name="minus"
                                    size="huge"
                                    style={{ color: "#444", margin: 5 }}
                                  />
                                )}
                              </div>
                            </GridColumn>
                          </>
                          : null
                        }
                      </>
                    )}
                </Grid>
              )}
          </GridColumn>
        </Grid>
      }
    />
  )
}

export default SwapIsOpen