import { graphqlApiQuery } from "./baseGraphqlApi"
import { getSharingpointDetaildata } from "../../graphql/queries"

const sharing_point_name = localStorage.getItem("my_sp_serial_number_is");

export const sharingpointDetailApi = graphqlApiQuery.injectEndpoints({
  endpoints: builder => ({
    fetchSharingpointDetailData: builder.query({
      // query: () => ({
      //   queryName: "getSharingpointDetaildata",
      //   data: {
      //   },
      // }),
      query: () => ({
        queryName: getSharingpointDetaildata,
        data: {
          sharing_point_name
        },
      }),
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
})
// console.log(sharingpointApi)

export const { useLazyFetchSharingpointDetailDataQuery } = sharingpointDetailApi