import { Icon } from "semantic-ui-react";

export default function StationOfflineMessage() {
  return (
    <div
      width="16"
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: 700,
            fontSize: 40,
            color: "#ff5a00",
            padding: 40,
          }}
        >
          I'm offline, please call support team for help.
        </div>
        <Icon name="warning sign" size="massive" style={{ color: "#ff5a00" }} />
        <div
          style={{
            fontWeight: 700,
            fontSize: 40,
            color: "#ff5a00",
            padding: 40,
          }}
        >
          +4930587047030
        </div>
      </div>
    </div>
  )
};