import { createSlice } from "@reduxjs/toolkit"
import { Auth } from "aws-amplify";
import jwtDecode from "jwt-decode";

const authSlice = createSlice({
  name: 'auth',
  initialState: { username: null, accessToken: null, isAdmin: false, allowedToFillIn: false },
  reducers: {
    setCredentials: (state, action) => {
      const { access_token, is_admin, allowed_to_fill_in } = action.payload
      const { username } = jwtDecode(access_token);
      // console.log(`User signed in as: ${username}`)
      state.username = username
      state.accessToken = access_token
      state.isAdmin = is_admin
      state.allowedToFillIn = allowed_to_fill_in
    },
    logOut: (state) => {
      state.username = null
      state.accessToken = null
      state.isAdmin = false
      state.allowedToFillIn = false
      Auth.signOut();
      localStorage.removeItem("last_touch_event_at")
    }
  },
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectAuth = (state) => state.auth
// export const selectCurrentUser = (state) => state.auth.username
// export const selectCurrentToken = (state) => state.auth.accessToken