import awsconfig from "../aws-exports/aws-exports-rel";


const handleTouchScreenEvent = () => {
  // console.log("last_interaction_event_at", new Date());
  localStorage.setItem("last_touch_event_at", new Date().getTime());
};

const getAccessToken = () => {
  const access_token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id
    }.${localStorage.getItem(
      `CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id}.LastAuthUser`
    )}.accessToken`
  );
  // console.log("access_token: ", access_token)
  return access_token
}

export { handleTouchScreenEvent, getAccessToken };