import React from "react";
import { useSelector } from "react-redux";
import { Grid, Segment } from "semantic-ui-react";
import { BeatLoader } from "react-spinners";
import { useParams } from "react-router-dom";

import { selectBatterySwapOrder, } from "../../store/batterySwapOrderSlice";
import useFrequentlyFetchingSwapOrderBySpName from "./useFetchSwapOrderBySpName";
import { useGetServiceRatingOptionsQuery } from "../../store/services/servicesRatingApi";

import HeaderLayout from "../HeaderLayout";
import useTimeout from "../../shared/hooks/useTimeout"
import LogoutAlert from "../LogoutAlert"

import SwapHasProblem from "./SwapHasProblem";
import SwapCompleted from "./SwapCompleted";
import SwapAborted from "./SwapAborted";
import SwapIsOpen from "./SwapIsOpen";

export default function Swap({
  stationMinimalData,
  t
}) {
  const { swapId } = useParams();
  useFrequentlyFetchingSwapOrderBySpName(swapId);
  const [showLogoutAlert, setShowLogoutAlert] = useTimeout()
  const { data: ratingOptions } = useGetServiceRatingOptionsQuery()

  const { swapOrderBySpName } = useSelector(selectBatterySwapOrder);


  return (
    <Grid>
      <HeaderLayout title={"Swap"} station={stationMinimalData} />
      {
        showLogoutAlert ? <LogoutAlert setShowLogoutAlert={setShowLogoutAlert} /> :
          (
            <>
              {!swapOrderBySpName
                ?
                // Show loading
                <div style={{ marginTop: 400, marginLeft: 600, zIndex: 60 }}>
                  <BeatLoader
                    color={"#ff5a00"}
                    loading={true}
                    height={10}
                    width={30}
                    style={{ zIndex: 50 }}
                  />
                </div>
                :
                <Segment
                  style={{
                    minHeight: 500,
                    borderRadius: 50,
                    backgroundColor: "#ff5a00",
                    position: "fixed",
                    top: 120,
                    left: 60,
                    zIndex: 40,
                    width: 1150,
                  }}
                >
                  {[
                    "LESS_TAKEN_THAN_ORDERED",
                    "MORE_TAKEN_THAN_ORDERED",
                    "MORE_INSERTED_THAN_ORDERED",
                    "LESS_INSERTED_THAN_ORDERED",
                    "HAS_PROBLEMS",
                  ].includes(swapOrderBySpName.swap_state)
                    ? <SwapHasProblem {...{ swapOrderBySpName }} />
                    : null
                  }

                  {[
                    "COMPLETED",
                    "CANCELED_WITH_PROBLEMS",
                    "COMPLETED_WITH_PROBLEMS",
                  ].includes(swapOrderBySpName.swap_state)
                    ? <SwapCompleted {...{ swapOrderBySpName, t, ratingOptions }} />
                    : null
                  }

                  {["ABORTED", "ABORTED_WITH_PROBLEMS"]
                    .includes(swapOrderBySpName.swap_state)
                    ? <SwapAborted />
                    : null}

                  {["OPEN"].includes(swapOrderBySpName.swap_state)
                    ? <SwapIsOpen {...{
                      swapOrderBySpName, t, stationMinimalData
                    }} />
                    : null}

                </Segment>
              }
            </>
          )
      }
    </Grid >
  )

}

