import React from "react";
import { Icon, Loader } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeTargetSharingpoint } from "../store/targetSharingpointSlice";
import { logOut } from "../store/authApiSlice";

const LeftBottomButton = ({ navigateTo, activateLogout }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { isLoadingLogout } = useSelector(state => state.login);
  const onLogout = () => {
    dispatch(logOut())
    dispatch(removeTargetSharingpoint())
  }

  return (
    <div
      onClick={() =>
        activateLogout ? onLogout() : navigate(navigateTo)
      }
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        backgroundColor: "#444",
        borderTopRightRadius: "100%",
        height: 130,
        width: 130,
        textAlign: "center",
        verticalAlign: "middle"
      }}
    >

      <Icon
        name={"arrow alternate circle left"}
        style={{ color: "white", marginTop: 40, marginRight: 20 }}
        size="huge"
      />


      {/* {!isLoadingLogout ? (
        <Icon
          name={"arrow alternate circle left"}
          style={{ color: "white", marginTop: 40, marginRight: 20 }}
          size="huge"
        />

      ) : (
        <Loader
          inline
          active
          inverted
          size="huge"
          style={{ color: "white", marginTop: 40, marginRight: 20 }}
        />
      )} */}
    </div>
  );
};

export default LeftBottomButton;
