import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import awsconfig from "../../aws-exports/aws-exports-rel";
import { getAccessToken } from '../../utils';


const baseQuery = fetchBaseQuery({
  baseUrl: awsconfig.FLASK_REST_API,
  prepareHeaders: (headers) => {
    headers.set("client_id", awsconfig.aws_user_pools_web_client_id)
    headers.set("access_token", getAccessToken())
    return headers;
  },
});

const baseQueryIPv4 = fetchBaseQuery({
  baseUrl: 'https://geolocation-db.com/json/',
});

export const sharingpointSetupApi = createApi({
  reducerPath: "sharingpointSetupApi",
  baseQuery,
  tagTypes: ["sharingpointSetup"],
  endpoints: (builder) => ({
    fetchAllStationIds: builder.query({
      query: () => ({
        url: '/sharing_point_ids',
        method: 'GET',
        timeout: 5000,
      }),
      providesTags: ["fetchAllStationIds"]
    }),

    unlinkSharingpoint: builder.mutation({
      query: ({ sp_serial_number }) => ({
        url: `/sharingpoints/${sp_serial_number}/settings/unlink`,
        method: 'POST',
      }),
      invalidatesTags: ["fetchAllStationIds"]
    }),

    postSharingpointSettings: builder.mutation({
      query: ({ sp_serial_number }) => ({
        url: '/ipc-app/settings',
        method: 'POST',
        body: {
          stationId: sp_serial_number,
        }
      }),
    }),
  })
})

export const sharingpointIPv4Api = createApi({
  reducerPath: "getIPv4",
  baseQuery: baseQueryIPv4,
  endpoints: (builder) => ({
    getIPv4: builder.query({
      query: () => ({
        url: '/',
        method: 'GET',
      }),
    }),
  })
})

export const { useFetchAllStationIdsQuery, useUnlinkSharingpointMutation, usePostSharingpointSettingsMutation } = sharingpointSetupApi
export const { useGetIPv4Query } = sharingpointIPv4Api