import React, { useState } from "react";
import {
  Grid,
  GridColumn,
  Segment
} from "semantic-ui-react";
import { upperFirst } from "lodash";
import BatteryImage from "../../components/BatteryImage";
import "./batteryItem.css"
import { useLongPress } from "use-long-press";

const BatteryItem = ({
  batteryType,
  handleClickOnBattery,
  t
}) => {
  const [loading, setLoading] = useState(false);
  const onClick = () => {
    setLoading(true)
    // console.log(`loading state: ${loading}`)
    handleClickOnBattery(batteryType)
  }
  const bindSelectCard = useLongPress(() => handleClickOnBattery(batteryType), {
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: "pointer"
  });
  return (
    // Card frame
    <Segment
      as={Grid}
      style={{
        borderRadius: 40,
        zIndex: 3,
        backgroundColor: "#E6E6E6",
        color: "#444444",
        overflow: "hidden"
      }}
      textAlign="center"
      onClick={onClick}
      className="cardFrame"
      {...bindSelectCard()}
    >

      {/* Card title */}
      <GridColumn width="16" style={{ marginTop: 30, marginBottom: 15 }}>
        <span
          as="h1"
          style={{ fontSize: 40, fontWeight: "bolder" }
          }
        >
          {upperFirst(batteryType.replaceAll("_", " ")).toUpperCase().split(" ", 2).join(" ")}
        </span>
      </GridColumn>

      {/* Spinner loading */}
      {loading && <dir style={{ position: "absolute", top: "50%" }}>
        <span className="custom-spinner">
        </span>
      </dir>}

      {/* batt image */}
      <div style={{ minHeight: "250px", maxHeight: "280px" }}>
        <BatteryImage
          type={batteryType.replaceAll("_", " ")}
          maxHeight={["greenpack", "kumpan", "Niu 60V"].includes(batteryType) ? "100%" : "140%"}
        />
      </div>
    </Segment>
  );
};

export default BatteryItem
