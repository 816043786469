import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { setCredentials } from "../../store/authApiSlice";
import { Auth } from "aws-amplify";
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { useRfidLoginMutation } from "../../store/services/authApi";

import { useLazyFetchSharingpointDetailDataQuery } from "../../store/services/sharingpointDetailApi";
import { setTargetSharingpoint } from "../../store/targetSharingpointSlice";
import { showNotificationError } from "../../store/notificationErrorSlice";
import { resetSwapOrder } from "../../store/batterySwapOrderSlice";

const sharing_point_name = localStorage.getItem("my_sp_serial_number_is");
const minRfidTagLength = 5
const maxRfidTagLength = 16

export default function useValidateRfid({ setLoginOnGoing, loginOnGoing }) {
  const dispatch = useDispatch();
  const [rfidTag, setRfidTag] = useState("");
  const [rfidLogin] = useRfidLoginMutation();

  const [fetchSharingpointDetailData] =
    useLazyFetchSharingpointDetailDataQuery();

  const handleUserKeyPress = useCallback((event) => {
    const { key } = event;
    if (!["Shift", "Alt", "Tab"].includes(key)) {
      setRfidTag((prevRfidTag) => `${prevRfidTag}${key}`);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
  const validatingOngoingRef = useRef(false);
  useEffect(() => {
    if (!validatingOngoingRef.current && !loginOnGoing &&
      ((rfidTag.includes("Enter") && rfidTag.length >= minRfidTagLength) ||
        rfidTag.length >= maxRfidTagLength)) {
      validatingOngoingRef.current = true
      const targetRfidTag = rfidTag.trim().replace("Enter", "")
      validateRFID(targetRfidTag);
    }

  }, [rfidTag]);


  const validateRFID = async (param_rfid) => {
    try {
      setRfidTag("");

      setLoginOnGoing(true);
      dispatch(resetSwapOrder())
      console.log(`Start validate RFID number: ${param_rfid}`);
      const { data, error } = await rfidLogin({ rfidTag: param_rfid });
      if (error) {
        throw (error.data)
      }
      const { access_token, id_token, is_admin } = data.data;

      // Graphql authentication
      const session = new CognitoUserSession({
        IdToken: new CognitoIdToken({
          IdToken: id_token,
        }),
        RefreshToken: new CognitoRefreshToken({
          RefreshToken: "",
        }),
        AccessToken: new CognitoAccessToken({
          AccessToken: access_token,
        }),
      });
      Auth.configure(session, "session");
      // const { username } = jwtDecode(access_token);

      const currentUser = Auth.createCognitoUser(
        session.getIdToken().decodePayload()["cognito:username"]
      );
      currentUser.setSignInUserSession(session);

      if (sharing_point_name) {
        const { data: sharingpointDetailData, } =
          await fetchSharingpointDetailData();
        const { allowed_to_fill_in, sharing_point_name, ongoing_swap } =
          sharingpointDetailData.sharingpoint.data;
        const { code, message } = sharingpointDetailData.sharingpoint;

        if (code !== 200 && message) {
          throw new Error(message)
        }

        if (ongoing_swap) {
          dispatch(
            showNotificationError({ message: "On going swap detected" })
          );
        }
        dispatch(
          setCredentials({ access_token, is_admin, allowed_to_fill_in })
        );
        dispatch(
          setTargetSharingpoint({
            selectedSharingpoint: sharing_point_name,
            data: sharingpointDetailData.sharingpoint.data,
          })
        );
      } else {
        dispatch(setCredentials({ access_token, is_admin }));
      }
      // setLoginOnGoing(false);
    } catch (error) {
      setRfidTag("");
      if (error?.message) {
        dispatch(showNotificationError({ message: error?.message }))
      }
      else {
        console.log(error);
        // dispatch(showNotificationError({ message: "Unsuccessful login" }))
      }
    }
    validatingOngoingRef.current = false
    setLoginOnGoing(false);
  };
}
