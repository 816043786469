import React, { useState } from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login";
import HomeLoggedIn from "./components/HomeLoggedIn";
import SelectBatteryType from "./components/SelectBatteryType";
import SingleSwap from "./components/SingleSwap";
import Settings from "./components/Settings";
import PublicInfo from "./components/PublicInfo";
import Swap from "./components/Swap";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import awsconfig from "./aws-exports/aws-exports-rel";
import Versioning from "./components/Versioning";
import scanRfidImg from "./assets/images/sticker_station_white.png";
import { ErrorBoundary } from "react-error-boundary";
import { Message } from "semantic-ui-react";
import OpenDoors from "./components/OpenDoors";
import { Amplify } from "aws-amplify";
import i18n from "./i18n";
import { useTranslation } from 'react-i18next';
import ProtectedRoute from "./components/ProtectedRoute";

import useFetchStationMinimalData from "./utils/useFetchStationMinimalData";
import useCleanUpUser from "./utils/useCleanUpUser";
import { handleTouchScreenEvent } from "./utils";


export default function App() {
  Amplify.configure(awsconfig)
  const { t } = useTranslation();
  const [stationMinimalData, setStationMinimalData] = useState();

  const sharing_point_name = localStorage.getItem("my_sp_serial_number_is");
  useFetchStationMinimalData({ setStationMinimalData });
  useCleanUpUser({ sharing_point_name })

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  function ErrorFallback({ error }) {
    return (
      <Message
        style={{ margin: 40 }}
        error
        header="Something went wrong! Redirect to Home page in 10 seconds"
        list={[error?.message]}
      />
    );
  }

  return (

    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={() => setTimeout(() => window.location = "/", 10000)}
    >
      <div onClick={() => handleTouchScreenEvent()} style={{ width: "100vw", height: "100vh" }}>
        <Routes >
          <Route path="/login" element={<Login {...{ stationMinimalData, scanRfidImg, changeLanguage, t }} />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/home-logged-in" element={<HomeLoggedIn {...{ stationMinimalData, t }} />} />
            <Route path={"/open-doors"} element={<OpenDoors {...{ stationMinimalData, t }} />} />
            <Route path="/single-swap" element={<SingleSwap {...{ stationMinimalData, t }} />} />
            <Route path="/settings" element={<Settings {...{ stationMinimalData, t }} />} />
            <Route path="/batteries" element={<SelectBatteryType {...{ stationMinimalData, t }} />} />
            <Route path="/swap/:swapId" element={<Swap {...{ stationMinimalData, t }} />} />
          </Route>
          <Route path="/public-info" element={<PublicInfo {...{ stationMinimalData }} />} />

          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
        <Versioning />
        <ToastContainer />

      </div>
    </ErrorBoundary >
  );
};
