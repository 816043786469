import React from "react";
import { Grid, Header, Button, GridColumn } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import HeaderLayout from "../HeaderLayout";
import LeftBottomButton from "../LeftBottomButton";

import useTimeout from "../../shared/hooks/useTimeout"
import LogoutAlert from "../LogoutAlert"
import { selectAuth } from "../../store/authApiSlice";

export default function HomeLoggedIn({
  t,
  stationMinimalData
}) {
  const navigate = useNavigate();
  const { username, isAdmin, allowedToFillIn } = useSelector(selectAuth);
  const [showLogoutAlert, setShowLogoutAlert] = useTimeout()

  return (
    // <Grid onClick={() => handleTouchScreenEvent()} columns="1">
    <Grid columns="1">
      <HeaderLayout title={"Menu"} station={stationMinimalData} />
      <LeftBottomButton activateLogout={true} />
      {showLogoutAlert ? <LogoutAlert setShowLogoutAlert={setShowLogoutAlert} /> :
        (
          <>
            <GridColumn
              textAlign="center"
              width="16"
              style={{
                position: "fixed",
                bottom: 350,

                zIndex: 21,
              }}
            >
              <Header style={{ fontSize: 40 }} as="h1">
                {`${t("Welcome")} ${username}`}
              </Header>
            </GridColumn>

            <Button
              style={{
                width: "350px",
                borderRadius: 50,
                backgroundColor: "#ff5a00",
                color: "white",
                fontSize: 40,
                position: "fixed",
                bottom: 110,
                left: 460,
                zIndex: 21,
              }}
              primary
              circular
              size="massive"
              disabled={!allowedToFillIn || !stationMinimalData}
              content={t("Open Modules")}
              onClick={() => navigate(allowedToFillIn && "/open-doors")}
            />

            <Button
              style={{
                width: "350px",
                borderRadius: 50,
                backgroundColor: "#ff5a00",
                color: "white",
                fontSize: 40,
                position: "fixed",
                bottom: 110,
                left: 60,
                zIndex: 21,
              }}
              content={t("Single swap")}
              primary
              size="massive"
              disabled={!stationMinimalData}
              onClick={() => navigate("/single-swap")}
            />

            <Button
              style={{
                width: "350px",
                borderRadius: 50,
                backgroundColor: "#ff5a00",
                fontSize: 40,
                position: "fixed",
                bottom: 110,
                left: 860,
                zIndex: 21,
              }}
              disabled={!isAdmin}
              primary
              circular
              size="massive"
              content={t("Settings")}
              onClick={() => navigate("/settings")}
            />
          </>
        )}
    </Grid>
  );
};
