import React, { useMemo, Fragment } from "react";
import {
  Grid,
  GridColumn,
} from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import LeftBottomButton from "../LeftBottomButton";
import HeaderLayout from "../HeaderLayout";

import BatteryItem from "../../shared/components/BatteryItem"
import useTimeout from "../../shared/hooks/useTimeout"
import LogoutAlert from "../LogoutAlert"
import { selectAuth } from "../../store/authApiSlice";
import { selectTargetSharingpoint } from "../../store/targetSharingpointSlice";


export default function SingleSwap({ stationMinimalData, t }) {

  const navigate = useNavigate();
  const [routeParams] = useSearchParams();
  const [showLogoutAlert, setShowLogoutAlert] = useTimeout()

  const { isAdmin } = useSelector(selectAuth);
  const { selectedSharingpoint, data: targetSharingpointData, } = useSelector(selectTargetSharingpoint);
  // console.log(selectedSharingpoint)
  const { force_to_swap_allowed, allowed_to_fill_in } = targetSharingpointData

  // console.log(`force_to_swap_allowed: ${force_to_swap_allowed}`)
  // console.log(`allowed_to_fill_in: ${allowed_to_fill_in}`)

  const selectedBatteryType = () => {
    if (!routeParams) return;
    const type = routeParams.get("selectedBatteryType")
    // console.log(type)
    return type
  }

  const sp_bat_ready_info = () => {
    const sp_bat_ready = JSON.parse(targetSharingpointData.sp_bat_ready_info);
    Object.keys(sp_bat_ready).forEach((key) => {
      !sp_bat_ready[key] && delete sp_bat_ready[key];
    });
    // console.log(sp_bat_ready)
    return sp_bat_ready;
  }

  const sp_bat_inside = () => {
    const sp_bat_inside_info = JSON.parse(
      targetSharingpointData.sp_bat_inside_info
    );
    Object.keys(sp_bat_inside_info).forEach((key) => {
      !sp_bat_inside_info[key] && delete sp_bat_inside_info[key];
    });
    // console.log(sp_bat_inside_info)
    return sp_bat_inside_info;
  }


  const dispatch = useDispatch();

  const handleClickOnBattery = (batteryType) => {
    navigate(
      `/batteries?selectedBatteryType=${batteryType}`
    );
  }

  return (
    <>
      <Grid
        textAlign="center"
        columns="equal"
        style={{
          margin: "auto",
          width: "90%",
          position: "fixed",
          top: 200,
          zIndex: 50,
          left: 70
        }}
      // onClick={() => handleTouchScreenEvent()}
      >
        <HeaderLayout title={t("Choose battery")} station={stationMinimalData} />
        {isAdmin ? <LeftBottomButton navigateTo={"/home-logged-in"} /> : <LeftBottomButton activateLogout={true} />}

        {showLogoutAlert ? <LogoutAlert setShowLogoutAlert={setShowLogoutAlert} /> : null}

        {!showLogoutAlert && (
          <>
            {Object.keys(
              force_to_swap_allowed
                ? sp_bat_inside()
                : sp_bat_ready_info()
            ).length === 0 && (
                <span
                  style={{
                    zIndex: 9999,
                    fontSize: 35,
                    color: "#444",
                    position: "fixed",
                    top: 400,
                  }}
                >
                  No batteries available
                </span>
              )}
            {Object.keys(
              force_to_swap_allowed
                ? sp_bat_inside()
                : sp_bat_ready_info()
            )
              .sort()
              .map((key) => (
                <Fragment key={key}>
                  {(force_to_swap_allowed
                    ? sp_bat_inside()[key] !== 0
                    : sp_bat_ready_info()[key] !== 0) && (
                      <GridColumn
                        key={key}
                        verticalAlign="bottom"
                        textAlign="center"
                        style={{ marginTop: 50, maxWidth: "500px" }}
                      >
                        <BatteryItem
                          {...{
                            selectedBatteryType,
                            isAdmin,
                            dispatch,
                            handleClickOnBattery,
                            t
                          }}
                          key={key}
                          batteryType={key}
                          sharing_point_name={selectedSharingpoint}
                          allowedToForceSwap={
                            force_to_swap_allowed
                          }
                        />
                      </GridColumn>
                    )}
                </Fragment>
              ))}
          </>
        )}
      </Grid>
    </>
  );
};
