import { createSlice } from "@reduxjs/toolkit";

const batterySwapOrderSlice = createSlice({
  name: "batterySwapOrder",
  initialState: {
    swapOrderBySpName: null,
    swapOrderCreatedSuccessful: false
  },
  reducers: {
    setSwapOrderBySpName: (state, actions) => {
      state.swapOrderBySpName = actions.payload.order.data;
      if (!state.swapOrderCreatedSuccessful) {
        const { confirmation_token } = state.swapOrderBySpName;
        if (confirmation_token) {
          state.swapOrderCreatedSuccessful = true
        }
      }
    },
    resetSwapOrder: (state, actions) => {
      console.log("--------resetSwapOrder------")
      state.swapOrderBySpName = null
      state.swapOrderCreatedSuccessful = false
    },
  }
});

export const {
  setSwapOrderBySpName, resetSwapOrder
} = batterySwapOrderSlice.actions;
export default batterySwapOrderSlice.reducer;

export const selectBatterySwapOrder = (state) => state.batterySwapOrder