import { useEffect } from "react";
// import { useConfirmOpenDoorMutation } from "../../store/services/sharingpointSwapOrderApi";


async function useSetSelectedModuleNumberInitial({ selectedModuleNumber, setSelectedModuleNumber, swap_status_map }) {
  // const [confirmOpenDoor, { isLoading }] = useConfirmOpenDoorMutation();
  useEffect(() => {
    const targetDoorArray = Object.keys(JSON.parse(swap_status_map))
    console.log(swap_status_map)
    // console.log(targetDoorArray)
    if (!targetDoorArray.includes("-1") && selectedModuleNumber === -1) {
      setSelectedModuleNumber(parseInt(targetDoorArray[0]))
      // console.log(`setSelectedModuleNumber to module ${targetDoorArray[0]}`)
    }
  }, [swap_status_map])
}

export default useSetSelectedModuleNumberInitial