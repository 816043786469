import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import awsconfig from "../../aws-exports/aws-exports-rel";
import jwtDecode from 'jwt-decode';
import { getAccessToken } from '../../utils';

const baseQuery = fetchBaseQuery({
  baseUrl: awsconfig.FLASK_REST_API,
  prepareHeaders: (headers) => {
    headers.set("client_id", awsconfig.aws_user_pools_web_client_id)
    headers.set("access_token", getAccessToken())
    return headers;
  },
});


export const servicesRatingApi = createApi({
  reducerPath: "service-rating",
  baseQuery,
  endpoints: (builder) => ({
    getServiceRatingOptions: builder.query({
      query: () => ({
        url: '/service-rating-options',
      }),
      invalidatesTags: ["service-rating-options"]
    }),

    updateRating: builder.mutation({
      query: ({ rating, reason, validation_hash_key, category = "POST_SWAP" }) => ({
        url: '/services-rating',
        method: 'POST',
        body: {
          username: jwtDecode(getAccessToken()),
          user_group_name: jwtDecode(getAccessToken()),
          rating,
          reason,
          validation_hash_key,
          category,
        }
      }),
    }),
  })
})

// const getAccessToken = () => {
//   const access_token = localStorage.getItem(
//     `CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id
//     }.${localStorage.getItem(
//       `CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id}.LastAuthUser`
//     )}.accessToken`
//   );
//   // console.log("access_token: ", access_token)
//   return access_token
// }

export const { useGetServiceRatingOptionsQuery, useUpdateRatingMutation } = servicesRatingApi