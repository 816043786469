import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import "./index.css";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import LeftBottomButton from "../LeftBottomButton";
import HeaderLayout from "../HeaderLayout";


import useTimeout from "../../shared/hooks/useTimeout"
import LogoutAlert from "../LogoutAlert"
import SelectBatteryNumber from "./SelectBatteryNumber";
import { selectTargetSharingpoint } from "../../store/targetSharingpointSlice";

export default function SelectBatteryType({ t, stationMinimalData }) {
  const [routeParams] = useSearchParams();
  const [showLogoutAlert, setShowLogoutAlert] = useTimeout()

  const { data: targetSharingpointData } = useSelector(selectTargetSharingpoint);
  const { force_to_swap_allowed } = targetSharingpointData

  const selectedBatteryType = routeParams.get("selectedBatteryType")

  const [isLoading, setIsLoading] = useState(false)

  const sp_bat_ready_info = () => {
    const sp_bat_ready = JSON.parse(targetSharingpointData.sp_bat_ready_info);
    Object.keys(sp_bat_ready).forEach((key) => {
      !sp_bat_ready[key] && delete sp_bat_ready[key];
    });
    // console.log(sp_bat_ready)
    return sp_bat_ready;
  }

  const sp_bat_inside = () => {
    const sp_bat_inside_info = JSON.parse(
      targetSharingpointData.sp_bat_inside_info
    );
    Object.keys(sp_bat_inside_info).forEach((key) => {
      !sp_bat_inside_info[key] && delete sp_bat_inside_info[key];
    });
    return sp_bat_inside_info;
  }

  return (
    <>
      <Grid
        textAlign="center"
        columns="1"
        style={{
          margin: "auto",
          width: "90%",
          position: "fixed",
          top: 120,
          zIndex: 50,
          left: 70,
          pointerEvents: isLoading ? "none" : ""
        }}
      >
        <HeaderLayout title={"Choose quantity"} station={stationMinimalData} />
        <LeftBottomButton navigateTo={"/single-swap"} activateLogout={false} />
        {showLogoutAlert
          ? <LogoutAlert setShowLogoutAlert={setShowLogoutAlert} />
          : <SelectBatteryNumber
            {...{ targetSharingpointData, t, isLoading, setIsLoading }}
            key={selectedBatteryType}
            batteryType={selectedBatteryType}
            batteryReady={
              sp_bat_ready_info()[selectedBatteryType] ? sp_bat_ready_info()[selectedBatteryType] : 0
            }
            battery_inside={
              sp_bat_inside()[selectedBatteryType] ? sp_bat_inside()[selectedBatteryType] : 0
            }
            allowedToForceSwap={
              force_to_swap_allowed
            }
          />}
      </Grid>
    </>
  );
};

