import React, { useState } from "react";
import Cookies from "universal-cookie";
import {
  Grid,
  GridColumn,
  Select,
  Segment,
  Form,
  FormField,
  Button,
  Label,
  Icon,
  Loader,
  Message,
} from "semantic-ui-react";
import HeaderLayout from "../HeaderLayout";
import useTimeout from "../../shared/hooks/useTimeout"
import LogoutAlert from "../LogoutAlert"
import {
  useFetchAllStationIdsQuery, useGetIPv4Query,
  usePostSharingpointSettingsMutation, useUnlinkSharingpointMutation
} from "../../store/services/sharingpointSetupApi"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showNotificationError } from "../../store/notificationErrorSlice";

export default function Settings({ stationMinimalData }) {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [stationId, setStationId] = useState(localStorage.getItem("my_sp_serial_number_is"));
  const [showLogoutAlert, setShowLogoutAlert] = useTimeout()
  const cookies = new Cookies();

  const { data: stationIdList, isLoading, error: errorFetchAllStationIdsQuery, refetch: refetchAllStationIdsQuery } = useFetchAllStationIdsQuery();
  const [unlinkSharingpoint] = useUnlinkSharingpointMutation();
  const [postSharingpointSettings] = usePostSharingpointSettingsMutation();
  const { data: ipAddress } = useGetIPv4Query();
  const unlink = async () => {

    await unlinkSharingpoint({ sp_serial_number: stationMinimalData.sp_serial_number })

    localStorage.removeItem("my_sp_serial_number_is");
    setStationId("")
  };

  const postSettings = async () => {
    try {

      console.log("Post sp settings")
      const { data } = await postSharingpointSettings({ sp_serial_number: stationId })
      console.log(data)

      const { hardware_id } = data.data;
      console.log(hardware_id)
      cookies.set("hardware_id", hardware_id, { path: "/" });

      localStorage.setItem("my_sp_serial_number_is", stationId);
      // dispatch(showNotificationError({ iconName: "checkmark", message: "Successfully saved sp serial number" }))


      window.location = "/";
      // navigate("/")

    } catch (error) {
      console.log(error)
      dispatch(showNotificationError({ message: "Setup error" }))
    }
  };

  return (
    <Grid>
      <HeaderLayout title="Settings" station={stationMinimalData} />

      <Grid
        as={Segment}
        columns="1"
        style={{
          width: 1150,
          borderRadius: 20,
          position: "fixed",
          top: 150,
          left: 70,
          zIndex: 100,
        }}
      >
        {showLogoutAlert ? <LogoutAlert setShowLogoutAlert={setShowLogoutAlert} /> :
          <>
            {isLoading
              ? (
                <GridColumn>
                  <Loader active inline size="big" />
                </GridColumn>
              )
              : stationIdList?.data
                ? (
                  <GridColumn>
                    <Form size="huge">
                      <FormField>
                        <label>sp serial number is linked to hardware id</label>
                        <Label
                          color="orange"
                          size="huge"
                          circular
                          content={stationMinimalData ? stationId : "-"}
                        />
                        <Icon name="linkify" />

                        {stationMinimalData && stationMinimalData.hardware_id ? (
                          <>
                            <Label
                              size="huge"
                              circular
                              content={stationMinimalData.hardware_id}
                              color="orange"
                            />
                            <Button
                              basic
                              content="unlink"
                              icon="unlink"
                              circular
                              onClick={() => unlink()}
                            />
                          </>
                        ) : (
                          "None"
                        )}
                      </FormField>
                      <FormField>
                        <label>Hardware Id</label>
                        {cookies.get("hardware_id")}
                      </FormField>
                      <FormField>
                        <label>IPv4</label>
                        {ipAddress && ipAddress.IPv4}
                      </FormField>
                      {(!stationMinimalData || !stationMinimalData.hardware_id || !stationId) && (
                        <FormField>
                          <label>Sp serial number</label>
                          {stationIdList?.data && (
                            <Select
                              search
                              clearable
                              fluid
                              style={{ borderRadius: "20px" }}
                              onChange={(_, { value }) => {
                                setStationId(value);
                              }}
                              compact
                              options={stationIdList?.data}
                              placeholder={"sp serial number ..."}
                            />
                          )}
                        </FormField>
                      )}

                      <FormField>
                        <Button
                          content="Save"
                          floated="right"
                          size="massive"
                          circular
                          style={{
                            backgroundColor: "#ff5a00",
                            color: "white",
                          }}
                          onClick={() => {
                            postSettings();
                          }}
                          disabled={!stationIdList?.data}
                        />
                        <Button
                          content="Cancel"
                          floated="right"
                          basic
                          size="massive"
                          circular
                          onClick={() => {
                            navigate("/home-logged-in");
                          }}
                        />
                      </FormField>
                    </Form>
                  </GridColumn>
                )
                : <GridColumn>
                  <Message size="massive" negative content={errorFetchAllStationIdsQuery?.message} />
                  <Button
                    content="Refresh"
                    floated="right"
                    size="massive"
                    circular
                    style={{
                      backgroundColor: "#ff5a00",
                      color: "white",
                    }}
                    onClick={refetchAllStationIdsQuery}
                  />
                </GridColumn>
            }

          </>}
      </Grid>
    </Grid>
  );
};
