import React, { useState } from "react";
import { Button, } from "semantic-ui-react";
import { BeatLoader } from "react-spinners";
import "./login.css";

import QRCode from "react-qr-code";
import useInternetOnlineStatus from "../../shared/hooks/useInternetOnlineStatus";
import ChooseLanguage from "./ChooseLanguage";
import StationOfflineMessage from "./StationOfflineMessage";
import useValidateRfid from "./useValidateRfid";
import useRedirectScreen from "./useRedirectScreen";
import HeaderLayout from "../HeaderLayout";
import touchableIcon from "../../assets/images/touchable-icon.png";

export default function Login({ stationMinimalData, scanRfidImg, changeLanguage, t }) {
  const [loginOnGoing, setLoginOnGoing] = useState(false)
  const [enableDevLogin, setEnableDevLogin] = useState(false)

  const [switchLanguageShown, setSwitchLanguageShown] = useState(false);

  const { internetAvailable } = useInternetOnlineStatus();


  useValidateRfid({ setLoginOnGoing, loginOnGoing })
  useRedirectScreen({ stationMinimalData });

  const simulateKeyPress = (string) => {
    const event = new KeyboardEvent('keydown', { key: string });
    window.dispatchEvent(event);
  }

  const { is_online: isOnlineString, validation_hash_key } = stationMinimalData ? stationMinimalData : {};
  const is_online = (isOnlineString?.toLowerCase() === 'true');

  const local_stored_sharing_point_name = localStorage.getItem("my_sp_serial_number_is");
  return (
    <div >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          top: "0px",
          bottom: "0px",
          margin: 0,
          position: "absolute",
        }}
        className="ui grid"
      >
        {switchLanguageShown
          ? <HeaderLayout title={t("Languages")} station={stationMinimalData} />
          : <HeaderLayout title={t("Station Details")} station={stationMinimalData} redirectTo={"/public-info"} {...{ enableDevLogin, setEnableDevLogin, icon: touchableIcon }} />
        }
        {internetAvailable && is_online
          ? <Button
            style={{
              backgroundColor: "white",
              color: "#ff5a00",
              width: "150px",
              position: "absolute",
              top: "20px",
              left: "100px",
            }}
            content={t("Language")}
            size="large"
            circular
            icon={"globe"}
            onClick={() => {
              setSwitchLanguageShown(!switchLanguageShown);
            }}
          />
          : null}
        {!loginOnGoing
          ? <div style={{ height: "100%", padding: 0 }}>
            {(is_online && !switchLanguageShown && internetAvailable && enableDevLogin) || (!local_stored_sharing_point_name)
              ? <div style={{
                display: "flex", flexDirection: "column", gap: "10px", justifyContent: "flex-start",
                position: "absolute", top: "90px", left: "20px"
              }}>
                {process.env.REACT_APP_MODE !== "production"
                  ? <>
                    <Button
                      style={{
                        backgroundColor: `${!local_stored_sharing_point_name ? "black" : "#F5F5F5"}`,
                        color: `${!local_stored_sharing_point_name ? "#F5F5F5" : "black"}`,
                      }}
                      content="Unlock without card"
                      size="large"
                      circular
                      icon={"unlock"}
                      onClick={() => {
                        simulateKeyPress("single-swap-bot-user");
                      }}
                    />

                    <Button
                      style={{
                        backgroundColor: `${!local_stored_sharing_point_name ? "black" : "#F5F5F5"}`,
                        color: `${!local_stored_sharing_point_name ? "#F5F5F5" : "black"}`,
                      }}
                      content="Unlock without card as admin"
                      size="large"
                      circular
                      icon={"unlock"}
                      onClick={() => { simulateKeyPress("0000000415691A596C80") }}
                    />
                  </>
                  : null
                }
              </div>
              : null
            }
            {internetAvailable && is_online && !switchLanguageShown &&
              stationMinimalData
              ? <div
                style={{
                  flexDirection: "column-reverse",
                  display: "flex",
                  height: "100%",
                }}
              >
                <div
                  // width="16"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "75%",
                    gap: "80px"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      backgroundColor: "#ff5a00",
                      flexBasis: "50%",
                      borderTopRightRadius: "100px 100px"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: 50,
                          lineHeight: "normal",
                          color: "#fff",
                          marginTop: 30,
                        }}
                      >
                        {t("With RFID Tag:")}
                      </div>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: 40,
                          color: "#fff",
                          lineHeight: "50px",
                          // padding: 40,
                        }}
                      >
                        {t("Place RFID Tag on")}<br />
                        {t("the Card Reader")}
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginTop: "70px" }}>
                        <div className="loginArrowSliding">
                          <div className="loginArrow"></div>
                        </div>
                        <div className="loginArrowSliding delay1">
                          <div className="loginArrow"></div>
                        </div>
                        <div className="loginArrowSliding delay2">
                          <div className="loginArrow"></div>
                        </div>
                      </div>
                      <img
                        alt=""
                        src={scanRfidImg}
                        height="250px"
                        width="auto"
                        style={{ transform: "scaleX(-1)", marginLeft: "200px" }}
                      />

                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      flexDirection: "column",
                      background: "#3F3E3E",
                      flexBasis: "50%",
                      borderTopLeftRadius: "100px 100px"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: 50,
                          lineHeight: "normal",
                          color: "#fff",
                          marginTop: 30,
                        }}
                      >
                        {t("With App:")}
                      </div>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: 40,
                          color: "#fff",
                          lineHeight: "50px",
                          // padding: 40,
                        }}
                      >
                        {t("Scan QR-Code")} <br />
                        {t("in Swobbee App")}
                      </div>
                    </div>

                    {validation_hash_key && (
                      <QRCode
                        // style={{ borderColor: "white", border: "2px" }}
                        // bgColor="#ff5a00"
                        fgColor="#3F3E3E"
                        value={`https://swobbee.de/dashboard-app?sp=${validation_hash_key}`}
                        size={250}
                      />
                    )}
                  </div>
                </div>
              </div>
              : null}

            {local_stored_sharing_point_name && (!internetAvailable || !is_online)
              ? <StationOfflineMessage />
              : !local_stored_sharing_point_name && internetAvailable
                ? <div
                  width="16"
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: "100%",
                    background: "#ff5a00",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 40,
                        padding: 40,
                        color: "white",
                      }}
                    >
                      {t("admin_setup_rfid_part_1")}
                    </div>
                    <img
                      alt=""
                      src={scanRfidImg}
                      height="350px"
                      width="auto"
                    />
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: 40,
                        color: "white",
                        padding: 40,
                      }}
                    >
                      {t("admin_setup_rfid_part_2")}
                    </div>
                  </div>
                </div>
                : null
            }

            {internetAvailable && switchLanguageShown
              ? <ChooseLanguage{...{ changeLanguage, t, setSwitchLanguageShown }} />
              : null
            }
          </div>
          : <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <BeatLoader
              color={"#ff5a00"}
              loading={true}
              height={10}
              width={30}
            />
          </div>
        }
      </div>
    </div>
  );
};

