import { useDispatch } from "react-redux";
import { useFetchSwapOrderBySpNameQuery } from "../../store/services/sharingpointSwapOrderApi";
import { resetSwapOrder, setSwapOrderBySpName } from "../../store/batterySwapOrderSlice";
import { useEffect } from "react";
import usePrevious from "../../shared/hooks/usePrevious";

export default async function useFrequentlyFetchingSwapOrderBySpName(swapId) {
  const dispatch = useDispatch()
  const { data, isSuccess } = useFetchSwapOrderBySpNameQuery(swapId, {
    pollingInterval: 2000,
  })
  useEffect(() => {
    if (isSuccess) {
      // console.log(data)
      dispatch(setSwapOrderBySpName(data))
    } else {
      // dispatch(resetSwapOrder())
    }
  }, [data, isSuccess]);

}
