import { graphqlApiMutation } from "./baseGraphqlApi";
import { openDoors } from "../../graphql/mutations";

export const openMultipleDoorsApi = graphqlApiMutation.injectEndpoints({
  endpoints: builder => ({
    openMultipleDoors: builder.mutation({
      query: ({ token, sharing_point_name, battery_type }) => ({
        queryName: openDoors,
        data: { token, sharing_point_name, battery_type },
      }),
      transformResponse: (response) => response,
    })
  }),
  overrideExisting: false,
})


export const {
  useOpenMultipleDoorsMutation
} = openMultipleDoorsApi
