import { configureStore } from "@reduxjs/toolkit"
import reducer from "./reducers"
import { authApi } from "./services/authApi";
import { servicesRatingApi } from "./services/servicesRatingApi";
import { graphqlApiQuery, graphqlApiMutation } from "./services/baseGraphqlApi";
import { stationMinimalDataApi } from "./services/stationMinimalDataApi";
import { sharingpointSetupApi, sharingpointIPv4Api } from "./services/sharingpointSetupApi";

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      servicesRatingApi.middleware,
      graphqlApiQuery.middleware,
      graphqlApiMutation.middleware,
      stationMinimalDataApi.middleware,
      sharingpointSetupApi.middleware,
      sharingpointIPv4Api.middleware,
    ])
});


