import React from "react";
import { Grid, GridColumn, Segment } from "semantic-ui-react";

const SwappingScreenLayout = ({ Left, Right, sameLayout = false }) => {
  return (
    <Grid columns="1" style={{ margin: "auto" }}>
      <GridColumn
        style={{
          fontSize: 30,
          textShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          position: "relative",
          textAlign: "center",
          color: "white",
          height: sameLayout ? 500 : null
        }}
        textAlign="center"
        width="8"
        verticalAlign="middle"
      >
        {sameLayout ?
          <Segment style={{ borderRadius: 20, height: "100%" }}>{Left}</Segment>
          : Left}
      </GridColumn>

      <GridColumn
        width="8"
        style={{
          fontSize: 30,
          textShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          color: "white",
          height: 500
        }}
        textAlign="center"
      >
        <Segment style={{ borderRadius: 20, height: "100%" }}>{Right}</Segment>
      </GridColumn>
    </Grid>
  );
};

export default SwappingScreenLayout;
