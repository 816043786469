import React from "react";
import { Header, Label } from "semantic-ui-react";
import leftIcon from "../assets/images/left-icon.png";
import useInternetOnlineStatus from "../shared/hooks/useInternetOnlineStatus";
import { useNavigate } from "react-router-dom";

const HeaderLayout = ({ title, station, redirectTo, setEnableDevLogin, enableDevLogin, icon }) => {
  const navigate = useNavigate()
  const { internetAvailable } = useInternetOnlineStatus();
  const { sp_serial_number, is_online, is_swap_ongoing, validation_hash_key } =
    station ? station : {};
  // console.log(sp_serial_number)
  const local_stored_sharing_point_name = localStorage.getItem("my_sp_serial_number_is");

  return (
    <>
      <img
        alt=""
        src={leftIcon}
        style={{
          position: "fixed",
          top: 10,
          left: 0,
          zIndex: 20,
          height: "auto"
        }}
        onClick={() => setEnableDevLogin ? setEnableDevLogin(!enableDevLogin) : null}
      />
      {title && <div
        style={{
          position: "fixed",
          top: "-65px",
          left: 400,
          backgroundColor: "#F5F5F5",
          width: 400,
          height: 200,
          borderBottomLeftRadius: 200,
          borderBottomRightRadius: 200,
          textAlign: "center",
          verticalAlign: "middle",
          zIndex: 2
        }}
        onClick={() => redirectTo ? navigate(`${redirectTo}`) : null}
      >
        <Header style={{ color: "#3F3E3E", marginTop: 80, marginBottom: 0, fontSize: 40, fontWeight: 700 }} as="h1">
          {title}
        </Header>
        {icon
          ? <img
            alt=""
            src={icon}
            style={{
              height: "30%"
            }}
          />
          : null
        }
      </div >}
      <span
        style={{
          fontSize: "30px",
          position: "fixed",
          top: 20,
          right: 20,
          zIndex: 70
        }}
      >
        {is_swap_ongoing && (
          <Label
            // className="shadow-button"
            content={"Swap ongoing"}
            size="huge"
            circular
            style={{
              backgroundColor: "#ECECEC",
              color: "#ff5a00",
            }}
          />
        )}
        {sp_serial_number ? <Label
          content={sp_serial_number}
          style={{ backgroundColor: "transparent", color: "#3F3E3E", fontSize: "20px", fontWeight: 400, padding: 0 }}
        />
          : local_stored_sharing_point_name
            ? <Label
              content={local_stored_sharing_point_name}
              style={{ backgroundColor: "transparent", color: "#3F3E3E", fontSize: "20px", fontWeight: 400, padding: 0 }}
            />
            : null}
        {!internetAvailable ? (
          <Label
            content={"No Internet connection"}
            size="huge"
            circular
            style={{
              backgroundColor: "#444",
              color: "white",
            }}
          />) : (
          <Label
            content={is_online === "true" ? "Online" : "Offline"}
            className="is-online"
            size="huge"
            circular
            style={{
              backgroundColor: "#ECECEC",
              color: "#ff5a00",
            }}
          />
        )}

      </span>
    </>
  );
};

export default HeaderLayout;
