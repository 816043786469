import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { logOut } from "../store/authApiSlice";
import { resetSwapOrder } from "../store/batterySwapOrderSlice";

export default function useCleanUpUser({ sharing_point_name }) {
  const dispatch = useDispatch();
  const shouldCancelRef = useRef(false);
  useEffect(() => {
    if (!shouldCancelRef.current && sharing_point_name) {
      dispatch(logOut())
      dispatch(resetSwapOrder())
    }
    return () => {
      shouldCancelRef.current = true;
    }
  }, []);
}
