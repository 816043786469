import { useEffect, useRef } from "react";
import { upperFirst } from "lodash";
import { showNotificationError } from "../../store/notificationErrorSlice";
import { useDispatch } from "react-redux";

export default function useWarningNotFullyChargedBattery({ batteryCount, batteryReady, batteryType }) {
  const dispatch = useDispatch()
  const shouldCancelRef = useRef();
  // console.log(`batteryCount: ${batteryCount} | batteryReady: ${batteryReady}`)
  useEffect(() => {
    if (!shouldCancelRef.current && batteryCount > batteryReady && batteryCount - batteryReady === 1) {
      dispatch(showNotificationError({
        // iconName: "warning",
        message: `By requesting more than
          ${batteryReady} ${upperFirst(
          batteryType.replaceAll("_", " ").substr(0, 10)
        )} batteries you'll get batteries with state of charge bellow 85%.`
      }))
    }
    return () => {
      if (batteryCount <= batteryReady) {
        // console.log(`batteryCount: ${batteryCount} | batteryReady: ${batteryReady}`)
        shouldCancelRef.current = false;
      } else {
        shouldCancelRef.current = true;
      }
    }
  }, [batteryCount]);
}