import React from "react";
import packagejs from "../../package.json";
import { useLocation } from 'react-router-dom';
export default function Versioning() {
  const { pathname } = useLocation();
  return (
    <>
      <div style={{
        color: `${pathname.includes("login") ? "white" : "#3F3E3E"}`,
        position: "fixed",
        bottom: "5px",
        // right: "90px",
        left: "5px",
        zIndex: "99",
        fontSize: "18px"
      }}>
        {process.env.REACT_APP_MODE}
      </div>
      <div style={{
        color: `${pathname.includes("login") ? "white" : "#3F3E3E"}`,
        position: "fixed",
        bottom: "5px",
        right: "5px",
        zIndex: "99",
        fontSize: "18px"
      }}>
        v{packagejs.version}
      </div >
    </>
  );
};
