import React from 'react'
import HeaderLayout from '../HeaderLayout'
import LeftBottomButton from '../LeftBottomButton'
import PublicInfoDisplay from './PublicInfoDisplay'


export default function PublicInfo({ stationMinimalData }) {


  return (
    <div
      className="ui grid"
    >
      <HeaderLayout station={stationMinimalData} />
      <LeftBottomButton navigateTo={"/"} />
      <PublicInfoDisplay {...{ stationMinimalData }} />
    </div>
  )
}