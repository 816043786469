import { combineReducers } from "redux";
// import loginReducer from "../loginSlice";
// import appStateReducer from "../appState.old";
// import swapOrderReducer from "../swapOrderSlice.old";
// import sharingpointReducer from "../sharingpointSlice";
// import userReducer from "../userSlice";
// import errorReduce from "../errorSlice";
// import commandReducer from "../commandSlice";

import batterySwapOrderSliceReducer from "../batterySwapOrderSlice"
import notificationErrorReducer from "../notificationErrorSlice"
import targetSharingpointReducer from "../targetSharingpointSlice";
import authReducer from "../authApiSlice";
import { servicesRatingApi } from "../services/servicesRatingApi";
import { authApi } from "../services/authApi";
import { graphqlApiQuery, graphqlApiMutation } from "../services/baseGraphqlApi";
import { stationMinimalDataApi } from "../services/stationMinimalDataApi";
import { sharingpointSetupApi } from "../services/sharingpointSetupApi";
import { sharingpointIPv4Api } from "../services/sharingpointSetupApi";

export default combineReducers({
  // sharingpoint: sharingpointReducer,
  // login: loginReducer,
  // appState: appStateReducer,
  // swapOrder: swapOrderReducer,
  // user: userReducer,
  // error: errorReduce,

  // command: commandReducer,

  batterySwapOrder: batterySwapOrderSliceReducer,
  notificationError: notificationErrorReducer,
  targetSharingpoint: targetSharingpointReducer,
  auth: authReducer,
  [servicesRatingApi.reducerPath]: servicesRatingApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [graphqlApiQuery.reducerPath]: graphqlApiQuery.reducer,
  [graphqlApiMutation.reducerPath]: graphqlApiMutation.reducer,
  [stationMinimalDataApi.reducerPath]: stationMinimalDataApi.reducer,
  [sharingpointSetupApi.reducerPath]: sharingpointSetupApi.reducer,
  [sharingpointIPv4Api.reducerPath]: sharingpointIPv4Api.reducer,
});
