import { Button, Grid, GridColumn } from "semantic-ui-react";
import Flag from "react-world-flags";

export default function ChooseLanguage({ t, changeLanguage, setSwitchLanguageShown }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Grid columns={2} textAlign="center" centered>
        <GridColumn style={{ color: "#fff" }} textAlign="center" width="16">
          {/* <div style={{ fontWeight: 700, fontSize: 60, color: "#fff" }}>
            {t("Choose a language")}
          </div> */}
        </GridColumn>
        <GridColumn textAlign="right">
          <Button
            className="shadow-button"
            style={{
              backgroundColor: "white",
              color: "#ff5a00",
              borderRadius: 20,
              height: 200,
              width: 250,
            }}
            content={<Flag code="de" height="90" />}
            size="massive"
            circular
            onClick={() => {
              changeLanguage("de");
              setSwitchLanguageShown(false);
            }}
          />
        </GridColumn>
        <GridColumn textAlign="left">
          <Button
            className="shadow-button"
            style={{
              backgroundColor: "white",
              color: "#ff5a00",
              borderRadius: 20,
              height: 200,
              width: 250,
            }}
            content={<Flag code="us" height="90" />}
            size="massive"
            circular
            onClick={() => {
              changeLanguage("en");
              setSwitchLanguageShown(false);
            }}
          />
        </GridColumn>
        <GridColumn textAlign="right">
          <Button
            className="shadow-button"
            style={{
              backgroundColor: "white",
              color: "#ff5a00",
              borderRadius: 20,
              height: 200,
              width: 250,
            }}
            content={<Flag code="es" height="90" />}
            size="massive"
            circular
            onClick={() => {
              changeLanguage("es");
              setSwitchLanguageShown(false);
            }}
          />
        </GridColumn>
        <GridColumn textAlign="left">
          <Button
            className="shadow-button"
            style={{
              backgroundColor: "white",
              color: "#ff5a00",
              borderRadius: 20,
              height: 200,
              width: 250,
            }}
            content={<Flag code="fr" height="90" />}
            size="massive"
            circular
            onClick={() => {
              changeLanguage("fr");
              setSwitchLanguageShown(false);
            }}
          />
        </GridColumn>
      </Grid>
    </div>
  );
};