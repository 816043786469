import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import awsconfig from "../../aws-exports/aws-exports-rel";

const baseQuery = fetchBaseQuery({
  baseUrl: awsconfig.FLASK_REST_API,
});

export const stationMinimalDataApi = createApi({
  reducerPath: "stationMinimalDataApi",
  baseQuery,
  endpoints: (builder) => ({
    fetchStationMinimalData: builder.query({
      query: (sharing_point_name) => ({
        url: `/sharingpoints/${sharing_point_name}/minimal`,
        method: 'GET',
      }),
    }),
    // keepUnusedDataFor: 25,
  })
})

export const { useFetchStationMinimalDataQuery } = stationMinimalDataApi