import { useState, useCallback } from "react";
import { useDispatch, } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLongPress } from "use-long-press";

import { upperFirst } from "lodash";
import { Button, Grid, GridColumn, Label } from "semantic-ui-react";
import BatteryImage from "../BatteryImage";
import useWarningNotFullyChargedBattery from "./useWarningNotFullyChargedBattery";

import { useCreateSwapOrderMutation, useValidateQrCodeMutation } from "../../store/services/sharingpointSwapOrderApi";
import { showNotificationError } from "../../store/notificationErrorSlice";

export default function SelectBatteryNumber({
  targetSharingpointData,
  batteryReady,
  batteryType,
  battery_inside,
  allowedToForceSwap,
  isLoading, setIsLoading,
  t
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [batteryCount, setBatteryCount] = useState(1);

  useWarningNotFullyChargedBattery({ batteryCount, batteryReady, batteryType })

  const [validateQrCode] = useValidateQrCodeMutation();
  const [createSwapOrder] = useCreateSwapOrderMutation();

  const onSwap = async () => {
    setIsLoading(true)
    const { data: validateQrCodeData } = await validateQrCode(targetSharingpointData.validation_hash_key)
    // console.log(validateQrCodeData)
    const unique_validation_hash_key = validateQrCodeData.validate_qr_code.data.unique_validation_hash_key
    console.log(unique_validation_hash_key)

    // console.log(battery_count, battery_type, sharing_point_name)

    const createSwapOrderResponse = await createSwapOrder({
      battery_count: batteryCount, battery_type: batteryType,
      sharing_point_name: targetSharingpointData.sharing_point_name, unique_validation_hash_key
    })
    // console.log(response)
    const { message, success } = createSwapOrderResponse.data.order;
    setIsLoading(false)
    if (success) {
      // console.log(`Navigate to /swap/${unique_validation_hash_key}`)
      navigate(`/swap/${unique_validation_hash_key}`)
    } else {
      dispatch(showNotificationError({ message: message }))
    }
  }

  const callbackPlusButton = useCallback(() => {
    setBatteryCount(batteryReady);
  }, []);

  const callbackMinusButton = useCallback(() => {
    setBatteryCount(0);
  }, []);
  const bindPlus = useLongPress(callbackPlusButton, {
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: "pointer"
  });

  const bindMinus = useLongPress(callbackMinusButton, {
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
    detect: "pointer"
  });



  return (
    <Grid
      textAlign="center"
      columns="equal"
      style={{
        marginLeft: "5%",
        borderRadius: 40,
        width: "100%",
        zIndex: 1,
        height: 800,
      }}
    >
      <GridColumn width={10} >
        <div
          style={{
            display: "flex", flexDirection: "column", width: "77%", borderTop: "solid 5px",
            borderRight: "solid 5px", borderLeft: "solid 5px", height: 475, margin: "auto",
            borderRadius: "60px 60px 0px 0px", backgroundColor: "white"
          }}>
          {/* Battery image */}
          <div style={{
            marginTop: 80
          }}>
            <BatteryImage
              type={batteryType.replaceAll("_", " ")}
              height="350px"
              width="auto"
            />
          </div>

        </div>
        {/* Requested battery number */}
        <div
          width="16"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            icon={{ name: "minus", size: "huge" }}
            style={{
              color: "black",
              backgroundColor: "white",
              width: 150,
              height: 150,
              border: "solid 5px",
            }}
            circular
            disabled={batteryCount <= 0}
            onClick={() => {
              if (batteryCount > 0) setBatteryCount(batteryCount - 1);
            }}
            {...bindMinus()}
          />

          <div
            style={{
              height: 50,
              width: 50,
              borderRadius: "150%",
              verticalAlign: "middle",
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                color: batteryCount > batteryReady ? "red" : "black",
                fontSize: 120,
                fontWeight: "bolder"
              }}
            >
              {batteryCount}
            </span>
          </div>

          <Button
            icon={{ name: "plus", size: "huge" }}
            circular
            disabled={
              allowedToForceSwap
                ? battery_inside === batteryCount
                : batteryCount === batteryReady
            }
            style={{
              color: "black",
              backgroundColor: "white",
              width: 150,
              height: 150,
              border: "solid 5px"
            }}
            onClick={() => {
              setBatteryCount(batteryCount + 1);
            }}
            {...bindPlus()}
          />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", width: "77%", borderRight: "solid 5px", borderLeft: "solid 5px", height: "100%", margin: "auto", backgroundColor: "white" }}>
          {/* ready battery number */}
          <Label
            content={`${batteryReady ? batteryReady : 0}/${battery_inside ? battery_inside : 0} Ready`}
            // size="huge"
            style={{
              // marginTop: "30px",
              padding: 0,
              fontSize: "30px",
              backgroundColor: "white",
            }}
          />
        </div>

      </GridColumn>
      <GridColumn width={6}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", height: "100%", rowGap: "20px" }}>
          {/* Battery label */}
          <div style={{ display: "flex", flexDirection: "column", rowGap: "40px" }}>
            <span
              style={
                { color: "black", fontSize: 30 }
              }

            >Battery Type</span>
            <span
              as="h1"
              style={
                { color: "black", fontSize: 60, fontWeight: "bolder" }
              }
            >
              {upperFirst(batteryType.replaceAll("_", " ")).toUpperCase().split(" ", 2).join(" ")}
            </span>
          </div>

          <Button
            content={t("Swap")}
            circular
            // size="large"
            className="swap-button"
            style={{
              margin: "75px 20px 0px",
              padding: "35px 0px",
              fontSize: 60,
              color: "white",
              backgroundColor: "#ff5a00",
              fontWeight: "bolder"
            }}
            disabled={batteryCount === 0 || targetSharingpointData.ongoing_swap}
            loading={isLoading}
            onClick={() => { onSwap() }}
          />
        </div>

      </GridColumn>
    </Grid>
  );
};