import { useEffect } from 'react'
import { handleTouchScreenEvent } from '../../utils'
import usePrevious from '../../shared/hooks/usePrevious'

function useUpdateLastInteraction({ swap_status_map, boxes }) {
  const swapStatusMapPrev = usePrevious(swap_status_map)
  const boxesPrev = usePrevious(boxes)
  useEffect(() => {

    const swapStatusMapPrevString = JSON.stringify(swapStatusMapPrev)
    const swapStatusMapString = JSON.stringify(swap_status_map)

    const boxesPrevString = JSON.stringify(JSON.stringify(boxesPrev))
    const boxesString = JSON.stringify(JSON.stringify(boxes))
    if (swapStatusMapPrevString && (boxesPrevString !== boxesString || swapStatusMapPrevString !== swapStatusMapString)) {
      // console.log(swapStatusMapPrevString)
      // console.log(swapStatusMapString)
      console.log("Reset timeout due to recent interaction on station")
      handleTouchScreenEvent()
    }
  })
}

export default useUpdateLastInteraction